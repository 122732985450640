import {
  emptySonarProject,
  InterfaceSonarProject,
  SonarProject,
} from "@/models/SonarProject.model";

import Vue from "vue";
import { Component, Ref, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { PageData } from "@/models/PageData.model";
import { InterfaceSonarServer } from "@/models/SonarServer.model";
import {
  InterfaceOrganization,
  InterfaceOrganizationSearchRequest,
  InterfaceOrganizationSearchResult,
} from "@/models/Organization.model";
import { Alert } from "@/models/Alert.model";
import {
  emptySearchByKeywordRequest,
  SearchByKeywordRequest,
} from "@/models/Search.model";
import SearchInput from "../searchInput/SearchInput.vue";
import { sanitizeUrl } from "@braintree/sanitize-url";

const sonarServers = namespace("SonarServers");
const organizations = namespace("Organizations");
const sonarProjects = namespace("SonarProjects");

interface SonarSelectList {
  text: string;
  value: string;
}

@Component({
  components: {
    SearchInput,
  },
})
export default class SonarProjects extends Vue {
  @Ref()
  createSonarProjectFormElement!: HTMLFormElement;
  @Ref()
  editSonarProjectFormElement!: HTMLFormElement;
  @Ref()
  createSonarProjectFormElementNameField!: HTMLFormElement;
  @Ref()
  editSonarProjectFormElementNameField!: HTMLFormElement;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public $keycloak: any;
  public sonarProject: InterfaceSonarProject = emptySonarProject();
  public createSonarProjectForm = false;
  public editSonarProjectForm = false;
  public deleteConfirmationForm = false;
  public hidden = false;
  public operationInProgress = false;
  public searchOrganizations = null;
  public searchRequest: SearchByKeywordRequest = emptySearchByKeywordRequest();
  public sonarServerSelectList: SonarSelectList[] = [];
  public sonarServerId = "";
  public forceDelete = false;

  @sonarServers.State
  public existingSonarServers!: InterfaceSonarServer[];

  @sonarServers.State
  public pageDataSonarServers!: PageData;

  @sonarServers.Action
  public getSonarServers!: (pageData: PageData) => Promise<boolean>;

  @organizations.State
  public selectedOrganization!: InterfaceOrganization;

  @organizations.State
  public pageDataOrganizations!: PageData;

  @organizations.State
  public foundOrganizations!: InterfaceOrganization[];

  @sonarProjects.State
  public pageDataSonarProjects!: PageData;

  @sonarProjects.State
  public existingSonarProjects!: InterfaceSonarProject[];

  @sonarProjects.State
  public notExistingSonarProject!: boolean;

  @sonarProjects.Mutation
  public setPageData!: (pageData: PageData) => void;

  @sonarProjects.Mutation
  public setSonarServerId!: (data: string) => void;

  @sonarProjects.State
  public sonarProjectSonarServerUrl!: string;

  @sonarProjects.State
  public sonarProjectAlert!: Alert;

  @sonarProjects.State
  public sonarProjectTableAlert!: Alert;

  @sonarProjects.State
  public inProgress!: boolean;

  @sonarProjects.Mutation
  public clearSonarProjectAlert!: () => void;

  @sonarProjects.Mutation
  public clearSonarProjectTableAlert!: () => void;

  @sonarProjects.Mutation
  public setForceDelete!: (data: boolean) => void;

  @sonarProjects.Mutation
  public setNotExistingSonarProject!: (data: boolean) => void;

  @organizations.Action
  public searchOrganization!: (
    searchRequest: InterfaceOrganizationSearchRequest
  ) => Promise<InterfaceOrganizationSearchResult>;

  @sonarProjects.Action
  public getOrganizationProjectMembership!: (
    organizationId: string
  ) => Promise<boolean>;

  @organizations.Action
  public getOrganizations!: (
    searchRequest: SearchByKeywordRequest
  ) => Promise<boolean>;

  @sonarProjects.Action
  public getSonarProjects!: (
    searchRequest: SearchByKeywordRequest
  ) => Promise<boolean>;

  @sonarProjects.Action
  public storeSonarProject!: (
    sonarProject: InterfaceSonarProject
  ) => Promise<boolean>;

  @sonarProjects.Action
  public updateSonarProject!: (
    sonarProject: InterfaceSonarProject
  ) => Promise<boolean>;

  @sonarProjects.Action
  public deleteSonarProject!: (
    sonarProject: InterfaceSonarProject
  ) => Promise<boolean>;

  get headers(): {
    text: string;
    align: string;
    value: string;
    sortable: boolean;
  }[] {
    const id = [{ text: "ID", align: "start", value: "id", sortable: false }];
    const name = [
      { text: "Name", align: "start", value: "name", sortable: false },
    ];
    const organizationId = [
      {
        text: "Organization ID",
        align: "start",
        value: "organizationId",
        sortable: false,
      },
    ];
    const organizationName = [
      {
        text: "Organization Name",
        align: "start",
        value: "organizationName",
        sortable: false,
      },
    ];
    const actions = [
      { text: "Actions", align: "start", value: "actions", sortable: false },
    ];
    if (this.$router.currentRoute.fullPath === "/sonar/projects") {
      if (Vue.prototype.$keycloak.realmAccess.roles.includes("admin")) {
        return id.concat(name, organizationId, organizationName, actions);
      }
      return name.concat(organizationName, actions);
    }
    if (Vue.prototype.$keycloak.realmAccess.roles.includes("admin")) {
      return id.concat(name, actions);
    }
    return name.concat(actions);
  }

  loadSonarServers(): void {
    this.getSonarServers(this.pageDataSonarServers).then(() => {
      this.sonarServerSelectList = this.existingSonarServers.map((server) => {
        return { text: server.name, value: server.id };
      });
      this.sonarServerId = this.sonarServerSelectList[0].value;
      this.setSonarServerId(this.sonarServerId);
    });
  }

  @Watch("pageDataSonarProjects.options", {
    deep: true,
  })
  optionsChange(): void {
    this.searchRequest.pageData.options = this.pageDataSonarProjects.options;
    this.loadSonarServers();
    this.contextLoadSonarProjects();
  }
  @Watch("searchRequest.keyword", {
    deep: true,
  })
  @Watch("sonarServerId", {
    deep: true,
  })
  searchValueChange(): void {
    this.searchRequest.pageData.options = this.pageDataSonarProjects.options;
    this.setSonarServerId(this.sonarServerId);
    this.contextLoadSonarProjects();
  }

  @Watch("searchOrganizations")
  onPropertyChanged(value: string): void {
    if (value && value.length > 2) {
      const organizationSearchRequest: InterfaceOrganizationSearchRequest = {
        name: value,
        pageIndex: 1,
        pageSize: 100,
      };
      this.searchOrganization(organizationSearchRequest);
    }
  }

  public rules = {
    required: (value: string): string | boolean => !!value || "Required.",
    name: (value: string): string | boolean => {
      const pattern = /^[a-zA-Z0-9-_]{3,50}$/;
      const onlyNumbers = /^\d+$/;
      return (
        (!onlyNumbers.test(value) && pattern.test(value)) ||
        "Invalid project name. Project names must be alphanumeric (without spaces) and at least 3 characters long and less than 50 characters."
      );
    },
  };

  private contextLoadSonarProjects() {
    if (this.$router.currentRoute.fullPath === "/sonar/projects") {
      this.loadAllSonarProjects();
    } else if (
      this.$router.currentRoute.fullPath.startsWith("/orgs/organizations/")
    ) {
      this.getOrganizationProjectMembership(
        this.$router.currentRoute.params.id
      );
    } else {
      // eslint-disable-next-line no-console
      console.error("No matched router path#5");
    }
  }

  public getProjectUrl(sonarProject: InterfaceSonarProject): string {
    const sonarServerUrl = sonarProject.sonarServerUrl;
    const organizationName = sonarProject.organizationName;
    const projectName = sonarProject.name;
    return sanitizeUrl(
      `${sonarServerUrl}/dashboard?id=${organizationName}_${projectName}`
    );
  }

  public loadAllSonarProjects(): void {
    this.getSonarProjects(this.searchRequest);
  }

  public editSonarProjectDialog(sonarProject: InterfaceSonarProject): void {
    this.sonarProject = new SonarProject(
      sonarProject.id,
      sonarProject.name,
      sonarProject.organizationId,
      sonarProject.organizationName,
      sonarProject.sonarServerId,
      sonarProject.sonarServerName,
      sonarProject.sonarServerUrl
    );
    this.editSonarProjectForm = true;
  }

  public async editSonarProjectDialogAction(
    sonarProject: InterfaceSonarProject
  ): Promise<void> {
    if (this.editSonarProjectFormElement.validate()) {
      this.operationInProgress = true;
      if (await this.updateSonarProject(sonarProject)) {
        this.closeEditSonarProjectForm();
      }
      this.operationInProgress = false;
      this.contextLoadSonarProjects();
    }
  }

  public deleteSonarProjectDialog(sonarProject: InterfaceSonarProject): void {
    this.sonarProject = sonarProject;
    this.deleteConfirmationForm = true;
  }

  changeToggleForceDelete(value: boolean): void {
    this.setForceDelete(value);
  }

  public async deleteSonarProjectDialogAction(
    sonarProject: InterfaceSonarProject
  ): Promise<void> {
    this.operationInProgress = true;
    if (await this.deleteSonarProject(sonarProject)) {
      this.closeDeleteSonarProjectDialog();
    }
    this.operationInProgress = false;
    this.contextLoadSonarProjects();
  }

  public async storeSonarProjectDialogAction(
    sonarProject: InterfaceSonarProject
  ): Promise<void> {
    if (this.createSonarProjectFormElement.validate()) {
      this.operationInProgress = true;
      if (
        this.$router.currentRoute.fullPath.startsWith("/orgs/organizations/")
      ) {
        sonarProject.organizationId = this.selectedOrganization.id;
      }
      if (await this.storeSonarProject(sonarProject)) {
        this.closeCreateSonarProjectForm();
      }
      this.operationInProgress = false;
      this.contextLoadSonarProjects();
    }
  }

  public closeCreateSonarProjectForm(): void {
    this.sonarProject = emptySonarProject();
    if (this.$router.currentRoute.fullPath.startsWith("/orgs/organizations/")) {
      this.createSonarProjectFormElementNameField.reset();
    } else {
      this.createSonarProjectFormElement.reset();
    }
    this.createSonarProjectForm = false;
    this.clearSonarProjectAlert();
  }

  public closeEditSonarProjectForm(): void {
    this.sonarProject = emptySonarProject();
    this.editSonarProjectForm = false;
    this.clearSonarProjectAlert();
  }

  public closeDeleteSonarProjectDialog(): void {
    this.sonarProject = emptySonarProject();
    this.deleteConfirmationForm = false;
    this.setNotExistingSonarProject(false);
    this.setForceDelete(false);
    this.clearSonarProjectAlert();
  }
}
