export interface InterfaceSonarProject {
  id: string;
  name: string;
  organizationId: string;
  organizationName: string;
  sonarServerId: string;
  sonarServerName: string;
  sonarServerUrl: string;
}

export interface InterfaceSonarProjectAudit {
  rev: number;
  id: string;
  name: string;
  organziationId: string;
  username: string;
  timestamp: string;
  revtype: number;
}

export const emptySonarProject = (
  organizationId = "",
  organizationName = "",
  sonarServerId = "",
  sonarServerName = "",
  sonarServerUrl = ""
): SonarProject => ({
  id: "",
  name: "",
  organizationId: organizationId,
  organizationName: organizationName,
  sonarServerId: sonarServerId,
  sonarServerName: sonarServerName,
  sonarServerUrl: sonarServerUrl,
});

export class SonarProject implements InterfaceSonarProject {
  constructor(
    public id: string,
    public name: string,
    public organizationId: string,
    public organizationName: string,
    public sonarServerId: string,
    public sonarServerName: string,
    public sonarServerUrl: string
  ) {}
}
